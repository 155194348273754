import React, { FC } from 'react';

export interface ContentConstraintProps {
	className?: string;
}

export const ContentConstraint: FC<ContentConstraintProps> = ({ children, className }) => (
	<div className={className}>
		<article className="col-xs-12 col-lg-10 col-lg-offset-1 col-xl-8 col-xl-offset-2">{children}</article>
	</div>
);

ContentConstraint.displayName = 'ContentConstraint';
