import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { useColorScheme } from '../../hooks/ColorScheme';

export interface LogoProps {
	className?: string;
}

export const Logo: FC<LogoProps> = ({ className }) => {
	const { scheme } = useColorScheme();

	if (scheme === 'dark') {
		return (
			<StaticImage
				placeholder="none"
				backgroundColor="rgba(255,255,255,0)"
				className={className}
				src="../../images/logo-w.svg"
				alt="Logo"
				width={94}
			/>
		);
	}

	return (
		<StaticImage
			placeholder="none"
			backgroundColor="rgba(255,255,255,0)"
			className={className}
			src="../../images/logo-b.svg"
			alt="Logo"
			width={94}
		/>
	);
};

Logo.displayName = 'Logo';
