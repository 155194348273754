import React, { FC } from 'react';
import { Link } from 'gatsby';
import { ContentConstraint } from '../../components/ContentConstraint/ContentConstraint';
import { Logo } from '../Logo/Logo';
import { ThemeToggle } from '../ThemeToggle/ThemeToggle';

import * as styles from './header.module.scss';

export const Header: FC = () => (
	<ContentConstraint>
		<header className={`row middle-xs between-xs ${styles.root}`}>
			<div className="col">
				<Link to="/" className={`${styles.brandWrapper} --no-indicator`}>
					<Logo />
				</Link>
			</div>
			<nav className={`col ${styles.navigation}`}>
				<ul>
					<li>
						<a href="#" onClick={() => window.open(`mailto:hello@janbiasi.ch`)}>
							Contact
						</a>
					</li>
					<li>
						<ThemeToggle />
					</li>
				</ul>
			</nav>
		</header>
	</ContentConstraint>
);

Header.displayName = 'Header';
