import {
	GrFacebook,
	GrInstagram,
	GrTwitter,
	GrGithub,
	GrLinkedin,
	GrMusic,
	GrLicense,
	GrFingerPrint,
} from 'react-icons/gr';
import { SiEthereum } from 'react-icons/si';

export const mapIconsToJSX = {
	twitter: GrTwitter,
	facebook: GrFacebook,
	instagram: GrInstagram,
	ethereum: SiEthereum,
	github: GrGithub,
	linkedin: GrLinkedin,
	music: GrMusic,
	key: GrLicense,
	fingerprint: GrFingerPrint,
} as const;

export type IconMapKeys = keyof typeof mapIconsToJSX;
