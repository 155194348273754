import React, { createContext, FC, useContext, useEffect, useState } from 'react';
// import { COLOR_SCHEME_KEY } from '../utils/storage';

export type ColorScheme = 'light' | 'dark';

const INITIAL_SCHEME: ColorScheme = 'light';

const DARK_MODE_QUERY = '(prefers-color-scheme: dark)' as const;

const getInitialColorScheme = (): ColorScheme => {
	// const userPreference = localStorage.getItem(COLOR_SCHEME_KEY) as ColorScheme;
	const isDarkNative = window.matchMedia && window.matchMedia(DARK_MODE_QUERY).matches;

	return isDarkNative ? 'dark' : 'light';
};

const ColorSchemeContext = createContext<{
	scheme: ColorScheme;
	updateScheme: (scheme: ColorScheme) => void;
}>({
	scheme: INITIAL_SCHEME,
	updateScheme: () => {},
});

ColorSchemeContext.displayName = 'ColorSchemeContext';

export const useColorScheme = () => {
	return useContext(ColorSchemeContext);
};

export const ColorSchemeProvider: FC = ({ children }) => {
	const [scheme, setScheme] = useState<ColorScheme>(INITIAL_SCHEME);

	const handleSchemeChange = (e: MediaQueryListEvent) => {
		const newColorScheme: ColorScheme = e.matches ? 'dark' : INITIAL_SCHEME;
		setScheme(newColorScheme);
	};

	useEffect(() => {
		// set initial theme according to last usage
		const initialState = getInitialColorScheme();
		if (scheme !== initialState) {
			setScheme(initialState);
		}

		// watch for system changes
		window.matchMedia(DARK_MODE_QUERY).addEventListener('change', handleSchemeChange);

		return () => {
			window.matchMedia(DARK_MODE_QUERY).removeEventListener('change', handleSchemeChange);
		};
	}, []);

	return (
		<ColorSchemeContext.Provider
			value={{
				scheme,
				updateScheme: setScheme,
			}}
		>
			{children}
		</ColorSchemeContext.Provider>
	);
};

ColorSchemeProvider.displayName = 'ColorSchemeProvider';
