import React, { FC } from 'react';
import { IconContext } from 'react-icons';
import { ColorSchemeProvider } from '../../hooks/ColorScheme';

export const Configurator: FC = ({ children }) => (
	<ColorSchemeProvider>
		<IconContext.Provider value={{ className: 'icon' }}>{children}</IconContext.Provider>
	</ColorSchemeProvider>
);

Configurator.displayName = 'Configurator';
