import React, { FC, useCallback } from 'react';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { ColorScheme, useColorScheme } from '../../hooks/ColorScheme';
import { COLOR_SCHEME_KEY } from '../../utils/storage';

import * as styles from './theme-toggle.module.scss';

export const ThemeToggle: FC = () => {
	const { scheme, updateScheme } = useColorScheme();

	const handleSchemeUpdate = useCallback(
		(scheme: ColorScheme) => {
			updateScheme(scheme);
			localStorage.setItem(COLOR_SCHEME_KEY, scheme);
		},
		[scheme]
	);

	return (
		<div className={styles.root}>
			<button
				title="Light mode (uses operation system theme per default)"
				className={`${styles.toggle} ${scheme === 'light' ? styles.activeToggle : ''}`}
				onClick={() => handleSchemeUpdate('light')}
			>
				<RiSunFill />
			</button>
			<button
				title="Dark mode (uses operation system theme per default)"
				className={`${styles.toggle} ${scheme === 'dark' ? styles.activeToggle : ''}`}
				onClick={() => handleSchemeUpdate('dark')}
			>
				<RiMoonFill />
			</button>
		</div>
	);
};

ThemeToggle.displayName = 'ThemeToggle';
