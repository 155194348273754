import React, { CSSProperties, FC } from 'react';
import { Helmet } from 'react-helmet';
import { useColorScheme } from '../../hooks/ColorScheme';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import * as styles from './layout.module.scss';

export interface LayoutProps {
	style?: CSSProperties;
}

export const Layout: FC<LayoutProps> = ({ children, style }) => {
	const { scheme } = useColorScheme();

	return (
		<div className={`${styles.root} ${styles[scheme]}`} style={style}>
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="author" content="Jan Biasi" />
				<meta name="copyright" content="Jan Biasi" />
				<meta name="keywords" content="Jan Biasi, Jan, Biasi, Avior, Avior Music, Avior DJ, giuvan" />
				<meta property="og:image" content="/assets/img/og-image.png" />
			</Helmet>
			<div className={styles.animator}>
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
			</div>
			<div className={styles.bar} />
			<div className="wrapper">
				<Header />
				<main className={styles.disposition}>{children}</main>
				<Footer />
			</div>
			<script 
				async 
				defer 
				data-website-id="9cc555cd-0dc6-4fe6-b2ad-8984057a83d3" 
				src="https://umami-production-2f73.up.railway.app/umami.js"
			></script>
		</div>
	);
};

Layout.displayName = 'Layout';
