import React, { FC } from 'react';
import Helmet from 'react-helmet';

export interface SEOProps {
	url?: string;
	title?: string;
	/**
	 * @see https://ogp.me/#types
	 */
	type?: string;
	image?: string;
	description?: string;
	language?: string;
}

export const SEO: FC<SEOProps> = ({ url, title, type = 'website', language = 'en', image, description }) => {
	const aggregatedTitle = title ? `${title} - Jan Biasi` : 'Jan Biasi';

	return (
		<Helmet title={aggregatedTitle} htmlAttributes={{ lang: language }}>
			<meta property="og:type" content={type} />
			<meta property="og:title" content={aggregatedTitle} />
			{description && <meta name="description" content={description} />}
			{description && <meta property="og:description" content={description} />}
			{url && <meta property="og:url" content={`https://janbiasi.ch${url}`} />}
			{image && <meta property="og:image" content={image} />}
		</Helmet>
	);
};

SEO.displayName = 'SEO';
