import React, { FC } from 'react';
import { ContentConstraint } from '../ContentConstraint/ContentConstraint';
import * as styles from './stage.module.scss';

export interface StateProps {
	title?: string;
	lead?: string | JSX.Element;
}

export const Stage: FC<StateProps> = ({ title, lead, children }) => {
	return (
		<ContentConstraint className={styles.root}>
			{title && <h1 className={styles.title}>{title}</h1>}
			{lead && <p className="lead">{lead}</p>}
			{children}
		</ContentConstraint>
	);
};
