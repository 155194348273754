import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GrLink } from 'react-icons/gr';
import type { GatsbyStaticData } from '../../schemas/gatsby';
import type { LinksSchema } from '../../schemas/links';
import { mapIconsToJSX } from '../../mapping/icons';
import { Logo } from '../Logo/Logo';
import { ContentConstraint } from '../../components/ContentConstraint/ContentConstraint';

import * as styles from './footer.module.scss';

interface FooterResponse {
	links: GatsbyStaticData<LinksSchema>;
}

export const Footer: FC = () => {
	const { links } = useStaticQuery<FooterResponse>(graphql`
		{
			links: allLinksJson {
				edges {
					node {
						id
						title
						url
						icon
						note
					}
				}
			}
		}
	`);

	return (
		<footer className={styles.root}>
			<ContentConstraint>
				<hr className={styles.separator} />
			</ContentConstraint>
			<section>
				<ContentConstraint>
					<div className={styles.navigation}>
						<div>
							<h3>About</h3>
							<p>
								I'm a software engineer, event manager,{' '}
								<a href="https://aviormusic.com" target="_blank" rel="noopener noreferrer">
									musician
								</a>{' '}
								&amp; crypto trader based in Switzerland.
							</p>
						</div>
						<div>
							<h3>Links</h3>
							<nav className={styles.socials}>
								<ul>
									{links.edges.map(({ node }) => {
										const Icon = node.icon ? mapIconsToJSX[node.icon] : GrLink;

										return (
											<li key={node.id}>
												<a className="--no-indicator" href={node.url} target="_blank" rel="noopener noreferrer">
													<Icon /> {node.title}
													{node.note && <sup>{node.note}</sup>}
												</a>
											</li>
										);
									})}
								</ul>
							</nav>
						</div>
						<div className="--text-center">
							<Logo className={styles.logo} />
						</div>
					</div>
				</ContentConstraint>
				<p className={styles.copyright}>Copyright &copy; {new Date().getFullYear()}, Jan Biasi, Switzerland</p>
			</section>
		</footer>
	);
};
